/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import ArticlePreview from '../components/_blog/ArticlePreview';
import ArticleFeatured from '../components/_blog/FeaturedPost/Other';
import ArticleFirstFeatured from '../components/_blog/FeaturedPost/First';
import ButtonReadMore from '../components/_blog/Button/ReadMore';
import useDetectId from '../hooks/useDetectId';
import NotFound from './404';
// import SeoDefault from '../seo.default.json';

const RootIndex = ({ data, location }) => {
	const [readMore, setReadMore] = useState(false);
	const { isId } = useDetectId();
	if (isId) return <NotFound />;

	const featuredPosts = get(data, 'featuredPost.edges');

	const posts = get(data, 'allContentfulBlogPost.edges');

	const firstFeatured = featuredPosts.slice(0, 1);
	const otherFeatured = featuredPosts.slice(1, 3);
	const firstLimitPost = posts.slice(0, 4);
	const otherPosts = posts.slice(4);

	const renderReadMore = () => {
		if (otherPosts.length > 0 && !readMore) {
			return (
				<ButtonReadMore
					onClick={() => {
						setReadMore(true);
					}}
				/>
			);
		}

		if (otherPosts.length > 0 && readMore) {
			return otherPosts.map(({ node }) => {
				return (
					<div
						key={node.slug}
						className="col-12 col-sm-12 col-md-12 col-lg-10 article-preview-hamburger"
					>
						<ArticlePreview article={node} />
					</div>
				);
			});
		}

		return <></>;
	};

	return (
		<>
			<Helmet title="Chatbiz.id | Blog">
				<html lang="id" />
				<meta
					name="description"
					content="Jelajahi semua informasi dan insight terbaru tentang chatbot, teknologi, dan binsis dari blog kami."
				/>
				<meta name="robots" content="index, follow" />
			</Helmet>
			<Layout location={location}>
				<section className="homepage-blog">
					<div className="wrapper-first-featured">
						<div className="container">
							<section className="first-featured-posts">
								{firstFeatured.map(({ node }) => {
									return (
										<div key={node.slug}>
											<ArticleFirstFeatured article={node} />
										</div>
									);
								})}
							</section>
						</div>
					</div>
					<div className="container">
						<section className="featured-posts">
							<div className="row">
								{otherFeatured.map(({ node }) => {
									return (
										<div key={node.slug} className="col-md-6">
											<ArticleFeatured article={node} />
										</div>
									);
								})}
							</div>
						</section>
						<section className="recent-posts">
							<div className="row">
								{firstLimitPost.map(({ node }) => {
									return (
										<div
											key={node.slug}
											className="col-12 col-sm-12 col-md-12 col-lg-10 article-preview-hamburger"
										>
											<ArticlePreview article={node} />
										</div>
									);
								})}
								{renderReadMore()}
							</div>
							{/* {numberOfPages > 1 && (
						<Link to="/page/2">
							<div className="btn btn-primary">Next Page</div>
						</Link>
					)} */}
						</section>
					</div>
				</section>
			</Layout>
		</>
	);
};

RootIndex.propTypes = {
	data: PropTypes.instanceOf(Object),
	location: PropTypes.string.isRequired,
};

export default RootIndex;

export const pageQuery = graphql`
	query HomeQuery {
		allContentfulBlogPost(
			sort: { fields: [createdAt], order: DESC }
			filter: { isFeatured: { eq: false } }
		) {
			edges {
				node {
					title
					slug
					createdAt
					description
					cover {
						fluid(maxWidth: 400, background: "rgb:000000") {
							...GatsbyContentfulFluid_withWebp
						}
					}
					author {
						name
						cover {
							fluid(maxWidth: 50, background: "rgb:000000") {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
				}
			}
		}
		featuredPost: allContentfulBlogPost(
			sort: { fields: [createdAt], order: DESC }
			filter: { isFeatured: { eq: true } }
		) {
			edges {
				node {
					title
					slug
					createdAt
					description
					cover {
						fluid(maxWidth: 800, background: "rgb:000000") {
							...GatsbyContentfulFluid_withWebp
						}
					}
					author {
						name
						cover {
							fluid(maxWidth: 50, background: "rgb:000000") {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
				}
			}
		}
		dtList: allContentfulBlogPost {
			edges {
				node {
					title
				}
			}
		}
	}
`;
