import styled from 'styled-components';
import { NETBOOK } from '../../../../assets/variable';

export const Title = styled.h1`
	font-family: Montserrat, 'Times New Roman', Serif;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 140%;
	color: #4f4f4f;
	margin-bottom: 27px;
`;

export const Wrapper = styled.div`
	background: #ffffff;
	border: none;
	.card {
		border: none;
		.thumbnailWrapper {
			border-radius: 8px;

			.thumbnail {
				width: 100%;
				height: 100%;
				border-radius: 8px;
			}
		}

		.card-block {
			display: flex;
			flex-direction: column;
			padding-top: 32px;
			padding-bottom: 32px;
			@media screen and (max-width: ${NETBOOK}) {
				padding: 30px;
			}
			.top-desc {
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;
				color: #4f4f4f;
			}
			.card-title {
				margin-top: 7px;
				a {
					font-style: normal;
					font-weight: bold;
					font-size: 1.8rem;
					line-height: 140%;
					color: #4f4f4f;
					&:hover {
						text-decoration: none;
					}
				}
			}

			.card-desc {
				font-family: Merriweather, 'Times New Roman', Serif;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;

				color: #4f4f4f;
				margin-top: 14px;
			}

			.card-link-to {
				display: flex;
				align-items: center;
				margin-top: 30px;

				&:hover {
					text-decoration: none;
				}
				p {
					font-family: Merriweather, 'Times New Roman', Serif;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 150%;
					color: #066fc2;
					margin: 0;
					padding: 0;
				}
				img {
					margin-left: 10px;
					width: 16px;
				}
			}
		}
	}
`;

export default Wrapper;
