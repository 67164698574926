import { useState } from 'react';
// di gunakan untuk mendeteksi /id dan malekukan disable meta robot di blog
const useDetectId = () => {
	const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '';
	const splitCurrentLocation = currentLocation.split('/');
	const [isId] = useState(splitCurrentLocation.includes('id'));
	return {
		isId,
	};
};

export default useDetectId;
