/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { convertToIdMonthDate } from '../../../../helper/time';
import { trimOn } from '../../../../helper/text';
import { SVGRender } from '../../../../helper/image';
import { Wrapper, Title } from './style';

const ArticleFirstFeatured = ({ article }) => {
	const { slug, title, cover, description, author } = article;
	return (
		<>
			<Title>Blog</Title>
			<Wrapper>
				<div className="card">
					<div className="row">
						<div className="col-12 col-sm-12 col-sm-12 col-lg-7  thumbnailWrapper">
							<Link to={`/blog/${slug}`}>
								<Img className="thumbnail" alt={title} fluid={cover ? cover.fluid : ''} />
							</Link>
						</div>
						<div className="col-12 col-sm-12 col-sm-12 col-lg-5">
							<div className="card-block">
								<div className="top-desc">
									{convertToIdMonthDate(article.createdAt)} | {author ? author.name : ''}
								</div>
								<h2 className="card-title">
									<Link className="libreFont boldtxt" to={`/blog/${slug}`}>
										{trimOn(title, 100)}
									</Link>
								</h2>

								<h4 className="card-desc">{trimOn(description || '', 250)}</h4>
								<Link to={`/blog/${slug}`} className="card-link-to">
									<p>Selengkapnya</p>

									{SVGRender(
										'https://seiturju.sirv.com/Chatbiz/arrow-right-blue.svg',
										'',
										'',
										'',
										'Go To',
										false,
										false,
									)}
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Wrapper>
		</>
	);
};

ArticleFirstFeatured.propTypes = {
	article: PropTypes.instanceOf(Object),
};

export default ArticleFirstFeatured;
